const SET_USER = "SET_USER";    
const LOGOUT = "LOGOUT";    

const defaultState = {
    currentUser: {},
    isAuth: null
}

export default function userReducer(state = defaultState, action){
    switch (action.type) {
        case SET_USER:
            if (action.payload !== null && action.payload.id !== null) {
                return{
                    ...state,
                    currentUser: action.payload,
                    isAuth: true
                }
            }else{
                return{
                    ...state,
                    currentUser: null,
                    isAuth: false
                }
            }
        case LOGOUT:
            localStorage.removeItem('token')
            localStorage.removeItem('isAdmin')
            localStorage.removeItem('role')
            
            return{
                ...state,
                currentUser: null,
                isAuth: false
            }
        default:
            return state
    }
}


export const setUser = user => ({type: SET_USER, payload: user})
export const logout = () => ({type: LOGOUT})