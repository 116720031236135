// config.js
const config = {
    // apiUrl: 'http://localhost:3001',
    apiUrl: 'https://first-cargo.kz:3001',
    
    nameCargo: 'First-Cargo'
  };
  
  
export default config;
  